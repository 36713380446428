/* References */
@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";
@import (reference) "libs/bootstrap.3.2.grid.less";

/***************************************/
/* Pinky Template */
/***************************************/
@LogoutHero-maxwidth: 780px;

.pinky-template {
    // Logout Page dependencies
    @import "flux/modules/Breadcrumbs.less";
    @import "flux/modules/ShowcaseCard.less";

    // Showcase Card Sub-Component: Showcase Card Contest
    @import "flux/modules/showcasecard/showcasecard-statusModifiers.less";
    @import "flux/modules/LikeCounter.less";

    // Layout dependencies
    @import "GetStarted.less";
    @import "ContestEntry.less";

    .main-content {
        background-color: @site-bg-color;
    }

    /***************************************/
    /* Logout Contest Hero */
    /***************************************/

    .LogoutHero-inner,
    .TopContest-inner,
    .GetStarted-inner,
    .ContestEntry-inner {
        .site-section-inner;
        overflow: hidden;
    }

    .LogoutHero {
        padding-bottom: 52px;
        background-color: @site-bg-color;

        &-inner {
            text-align: center;

            &::before { // used for the top border before top contest section
                content: '';
                display: inline-block;
                height: 1px;
                width: 100%;
                margin-bottom: 35px;
                background-color: @septenary-color-xlight;
            }
        }

        &-column {
            position: relative;
            display: inline-block;
            vertical-align: top;
            text-align: left;

            &:first-child {
                width: 37%;
                min-width: 400px;
                text-align: center;
            }

            &:last-child {
                width: 33%;
                min-width: 380px;
                padding-top: 74px;
            }
        }

        &-imageFrame {
            display: inline-block;
            height: 348px;
            width: 395px;
            margin: 0 auto;
            background: url('/static/css/images/contests/featured-work-frame.png') center top no-repeat;
            background-size: 85%;
        }

        &-featuredImage {
            display: inline-block;
            position: relative;
            top: 40px;
            width: 276px;
            height: 276px;
            margin: 0 auto;
            overflow: hidden;
            border: 1px solid @border-color-light;

            img {
                .vertical-align(@pos:relative);
            }
        }

        &-winnerLabel {
            width: 100%;
            .horizontal-align;

            &Text {
                display: inline-block;
                height: 34px;
                padding: 2px 5px;
                text-transform: uppercase;
                .type(heading2);
                line-height: 1.2;
                font-weight: bold;
                background: url('/static/css/images/contests/winner-label-sprite.png') 0 -68px repeat-x @bg-color-light;
            }

            &::before, // used fas background container for winner label
            &::after {
                content: '';
                display: inline-block;
                width: 23px;
                height: 34px;
                vertical-align: top;
                background: url('/static/css/images/contests/winner-label-sprite.png') no-repeat @bg-color-light;
            }

            &::after {
                background-position: 0 -34px;
            }
        }

        &-btnPostContest {
            width: 90%;
        }

        &-heading {
            .type(heading2);
            margin: 0;
        }

        &-desc {
            .type(heading3);
            line-height: 1.7;
            margin: 2px 0 32px;
        }
    }

    .is-mobile-first & {
        @media (max-width: @LogoutHero-maxwidth) {
            .LogoutHero {

                &-imageFrame {
                    display: none;

                    &-mobile {
                        display: block !important;
                        width: 95%;
                        margin: 30px auto;
                        text-align: center;
                        background-position: center;
                    }
                }

                &-column {
                    width: 100%;
                    min-width: 0;
                    margin: 0;
                }
                &-btnPostContest {
                    width: 100%;
                }

                &-wrapper {
                    position: relative;
                }

                &-frame {
                    display: block !important;
                    position: relative;
                    width: 100%;
                    text-align: center;
                    margin-top: 30px;
                }

                &-frameImage {
                    width: 100%;
                }

                &-noWinnersImage {
                    position: absolute;
                    top: 12%;
                    left: 0;
                    width: 100%;
                    padding: 0 10%;
                }

                &-imageLink {
                    display: block;
                    width: 100%;
                    .vertical-align(@pos:absolute);
                }

                &-winnerImage {
                    width: 100%;
                    padding: 0 10%;
                }
            }

            .TopContest {
                &-item {
                    width: 100%;
                }
            }

            .showcasecard {
                width: 100%;
                max-width: 350px;
            }

            .showcasecard-image {
                max-width: none;

                & > img {
                    width: 100%;
                }
            }
        }
    }

    /***************************************/
    /* Top Contests  */
    /***************************************/

    .TopContest {
        text-align: center;
        padding: 69px 0 42px;
        background-color: @bg-color;

        &-cards-wrapper {
            padding: 0;
            margin-bottom: 18px;
            list-style: none;
            overflow: hidden;
        }

        &-heading {
            .type(heading2);
            margin-bottom: 40px;
        }

        &-cards {
            .flex();
            .flex-justify(center);
            .flex-wrap(wrap);
            .list-unstyled;
            margin: 0 -10px;
        }

        &-item {
            .flex();
            .make-xs-column(3);
            margin: 12px 0;
        }
    }

    .is-mobile-first & {
        @media (@LogoutHero-maxwidth) {

            .TopContest {
                &-item {
                    width: 100%;
                }
            }
        }
    }

    .contestCard-cardLink {
        height: 208px; // custom height of cards
        .type(ui);
    }
}
