/**
 * Showcase Card
 *
 * Showcase Cards are used to showcase blog articles thumbnails, users profiles and users projects.
 * LESS Module: ShowcaseCard.less
 *
 * Markup: showcasecard.html
 *
 * Styleguide: Cards - Showcase Card
 */

/**
 * Showcase Card Horizontal
 *
 * This is a modifier for the Showcase Card. It can be used to showcase blog articles thumbnails, users profiles and users projects.
 * LESS Module: ShowcaseCard.less
 *
 * Markup: showcasecard-horizontal.html
 *
 * Styleguide: Cards - Showcase Card Horizontal
 */

@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";

/* Animations */
@import "mixins/animated/fadeInUp.less";
@import "flux/modules/LikeCounter.less";

@ShowcaseCard-gradientColor-primary: fade(@quaternary-color-xxxdark, 35%);
@ShowcaseCard-gradientColor-secondary: fade(@quaternary-color-xxxdark, 10%);
@ShowcaseCard-textColor-primary: @text-color-dark;
@ShowcaseCard-textColor-secondary: @text-color-mid;

.ShowcaseCard {
    @this: ShowcaseCard;
    .flex();
    .flex-direction(column);
    .card();
    position: relative;
    width: 100%;
    overflow: hidden;
    border: 0;
    transition: 0.2s ease-in-out;

    &:hover {
        .box-shadow-level-02();
    }

    &--static {
        &:hover {
            box-shadow: 0 0 4px 0 @card-shadow-level-1, 0 2px 4px 0 @card-shadow-level-1-secondary;
        }
    }

    &-cover {
        position: relative;
        width: 100%;
        min-height: 50px;
        border-radius: @border-radius;
        background: @quaternary-color-xxxxlight;

        &-link {
            width: 100%;
            height: 100%;
            color: inherit;

            &:hover {
                color: inherit;
            }
        }

        &:hover {
            .@{this}-overlay {
                opacity: 1;
            }
        }
    }

    &-figure {
        overflow: hidden;

        &-image {
            display: block;
            width: 100%;
        }

        &--fixed {
            .flex();
            .flex-justify(center);
            .flex-align-items(center);
            height: 210px;
            background: @quaternary-color-xxdark;

            .@{this}-figure-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    // Actions is optional
    &-actions {
        .flex();
        .flex-align-items(center);
        .flex-justify(center);
        padding: 8px 20px;
        background: @quaternary-color-xxxxxlight;
    }

    &-details {
        .type(ui);
        padding: 11px 20px;
        text-align: left;
        color: @text-color-dark;

        &-primary {
            display: block;
            font-weight: bold;
            .text-wrap();
        }

        /* Just in case it's used as a link */
        &-secondary:hover {
            color: @text-color-dark;
        }

        &-cta {
            margin-top: 8px;
        }

        &--clickable {
            cursor: zoom-in;
        }
    }

    // Footer is optional
    &-footer {
        .flex();
        .flex-justify(space-between);
        .flex-align-items(center);
        margin: auto 20px 0;
        padding: 14px 0;
        border-top: 1px solid @border-color-light;
        .type(ui);
        color: @ShowcaseCard-textColor-secondary;

        &-primary {
            margin-right: 12px;
        }

        &-secondary {
            margin-left: auto;

            .LikeCounter {
                .flex();
                align-items: center;
                color: currentColor;

                .Icon {
                    margin-right: 4px;

                    &.is-Icon-filled {
                        .Icon-image {
                            fill: @senary-color;
                        }
                    }
                }
            }
        }
    }

    /* Overlay is optional*/
    &-overlay {
        opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 3;
        overflow-y: auto;
        border-top-left-radius: @border-radius;
        border-top-right-radius: @border-radius;
        text-align: center;
        background: linear-gradient(@ShowcaseCard-gradientColor-primary, @ShowcaseCard-gradientColor-secondary);
        .transition(0.2s, ease-in-out);

        &--simple {
            background: fade(@quaternary-color-xxxdark, 75%);
        }

        &--zoom {
            /* The cursor pointer is needed as fallback for IE */
            cursor: pointer;
            cursor: zoom-in;

            &--freemiumLimited {
                cursor: default;
            }
        }

        &-inner {
            .flex(flex);
            .flex-align-items(center);
            .flex-justify(center);
            flex-flow: column nowrap;
            width: 100%;
            height: 100%;
        }

        &-cta {
            cursor: pointer;

            &:not(:last-child) {
                margin-right: 10px;
            }
        }
    }

    &--horizontal {
        .flex-direction(row);

        .@{this}-cover {
            width: 50%;
        }

        .@{this}-details-wrapper {
            .flex();
            .flex-direction(column);
            .flex-justify(flex-end);
            width: 50%;
        }

        .@{this}-details-primary,
        .@{this}-details-secondary {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .@{this}-footer {
            margin: 0 20px;
        }

        .@{this}-cover {
            height: 210px;
        }

        .@{this}-figure {
            .horizontal-vertical-align(@pos:absolute);
            width: 280px;
            height: 240px;
            overflow: hidden;
            padding-top: 6%;

            &-image {
                .vertical-align(@pos:absolute);
            }
        }
    }
}
