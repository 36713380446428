// Showcase Cards Modifiers

// 1. Overlay is an optional sub-component
// 2. Add to collection functionality on hold

@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";

/* Animations */
@import "mixins/animated/fadeInUp.less";

.ShowcaseCard {
    @this: ShowcaseCard;

    &-status {
        .type(ui);
        float: left;
        font-weight: bold;
        line-height: 1;
        vertical-align: middle;
    }

    &--rejected,
    &--withdrawn {
        position: relative;

        &::before { // dim rejected and withdrawn cards
            .absolute-cover;
            background-color: @bg-color;
            border-radius: @border-radius;
            content: "";
            cursor: default;
            opacity: 0.7;
            z-index: 7;
        }

        a {
            text-decoration: none;
        }

        .Rating {
            display: none;
        }

        .@{this}-details-primary {
            display: none;
        }

        .@{this}-details-secondary {
            position: absolute;
            top: 15px;
        }

        .@{this}-status {
            position: relative;
            z-index: 4;

            &Link {
                color: @text-color-dark;
            }
        }

        .card-rating {
            display: none;
        }

        .@{this}-footer {
            min-height: 49px;
        }
    }

    &--withdrawn {
        .@{this}-inner {
            background-color: @quaternary-color-xxxxlight;

            .@{this}-image {
                visibility: hidden;
            }
        }

        .flicon-sealed {
            display: none;
        }

        .LikeCounter {
            visibility: hidden;
        }
    }

    &--sealed {
        cursor: default;

        .@{this}-sealed {
            background: url("/static/img/contest/sealed.svg") center no-repeat @bg-color;
            background-size: 40%;
            display: inline-block;
            height: 169px;
            margin: 0;
            overflow: hidden;
            width: 100%;
        }

        .Rating {
            display: none;
        }

        .@{this}-details-name:hover {
            cursor: default;
            text-decoration: none;
        }

        .LikeCounter {
            &:hover {
                color: @quaternary-color-xxxlight;
                cursor: default;
                text-decoration: none;

                .flicon-heart {
                    fill: @quaternary-color-xxxlight;
                }
            }
        }

        .@{this}-footer {
            min-height: 49px;
        }
    }

    &.@{this}--winner {
        border: 1px solid @octonary-color-dark !important; // override transparent border
        border-radius: 6px;

        .@{this}-inner {
            max-height: 210px;
            min-height: 210px;
        }
    }

    &-winnerIcon {
        background-color: @octonary-color-dark;
        display: inline-block;
        padding: 5px 5px 0;
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 8;

        &::before { /* arrow accent for the trophy badge */
            border-color: @octonary-color-dark transparent transparent transparent;
            border-style: solid;
            border-width: 8px 17px 0;
            content: "";
            display: inline-block;
            height: 0;
            position: absolute;
            right: 0;
            top: 34px;
            width: 0;
        }

        [class*="flicon-"] {
            fill: @septenary-color-xxxlight;
            stroke: @septenary-color-xxxlight;
        }

        &--medal {
            background-color: @tertiary-color-light;
            padding: 5px 0 0;

            &::before {
                border-color: @tertiary-color-light transparent transparent transparent;
                border-width: 5px 12px 0;
                top: 30px;
            }
        }
    }

    &.@{this}--selected {
        border: 1px solid @primary-color;
        border-radius: 6px;
    }

    &--highlight {
        .@{this}-highlight-ribbon {
            background: url("/static/img/contest/highlight-ribbon.svg") center no-repeat;
            content: "";
            display: inline-block;
            height: 16px;
            left: 20px;
            position: absolute;
            top: -4px;
            width: 60px;
            z-index: 4;
        }
    }

    &--contest {
        overflow: visible;
        position: relative;

        .@{this}-image-container {
            border-radius: @border-radius @border-radius 0 0;
            display: block;
            max-height: 210px;
            min-height: 210px;
            overflow: hidden;
            vertical-align: middle;

            .@{this}-image {
                display: block;
                height: 210px;
                left: 50%;
                margin-left: -50%;
                object-fit: cover;
                position: relative;
                width: 100%;

                &-textEntry {
                    left: inherit;
                    margin-left: inherit;
                    margin-top: inherit;
                    width: 100%;
                }
            }
        }

        .@{this}-overlay {
            max-height: 210px;

            &-content {
                .btn--entryCard {
                    margin: 37px 2px 0;

                    .online-status {
                        margin-right: 7px;
                    }
                }
            }

            &-inner--static {
                display: flex;
                position: absolute;
                top: 0;

                .@{this}-overlay-content {
                    padding-top: @spacing-xxsmall;
                }
            }
        }

        .@{this}-inner {
            border-radius: @border-radius @border-radius 0 0;
            max-height: 210px;
            min-height: 210px;
            overflow: visible;
            position: relative;
        }

        &:hover {
            &.ShowcaseCard--rejected,
            &.ShowcaseCard--withdrawn {
                .ShowcaseCard-inner {
                    z-index: 7;
                }
            }
        }

        .@{this}-details {
            min-height: 70px;
            position: relative;
            z-index: 6;
        }

        .@{this}-cover:hover {
            .ShowcaseCard-price {
                display: none;
            }
        }

        .@{this}-state-link {
            background: none;
            border: none;
            display: block;
            font-size: 13px;
            margin: 5px auto;
            padding-top: 9px;
            text-decoration: underline;
        }

        .@{this}-price {
            .font-size(18px);
            background: @quinary-color-dark;
            color: @text-color-light;
            font-weight: bold;
            padding: 4px;
            position: absolute;
            right: 0;
            top: 185px;

            &::before {
                .transition(0.2s, ease-in-out);
                border-color: transparent @quinary-color-dark  transparent transparent;
                border-style: solid;
                border-width: 33px 6px 0 0;
                content: "";
                height: 0;
                left: -6px;
                position: absolute;
                top: 0;
                width: 0;
            }
        }

        .@{this}-footer {
            &-secondary {
                color: @quaternary-color-xxxlight;
            }
        }

        .flicon-medal {
            fill: @septenary-color-xxxlight;
            height: 20px;
            stroke: none;
        }

        .flicon-trophy-alt {
            fill: @septenary-color-xxxlight;
        }

        .flicon-sealed {
            margin: -7px 0 -5px 2px;
            vertical-align: middle;
            width: 16px;
        }
    }

    &--multiAward {
        border: 1px solid @border-color-dark;
        border-radius: @border-radius;
    }

    &--handover {
        .@{this}-cover {
            border-radius: 5px 5px 0 0;
            overflow: hidden;
        }

        .@{this}-overlay {
            max-height: none;
        }

        .@{this}-winnerIcon {
            right: 15px;
            top: -10px;
        }

        .@{this}-details {
            line-height: 1.8;
            padding: 25px;
        }
    }
}
