/* References */
@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";

/* Private Variables; */
@LikeCounter-defaultColor: @quaternary-color-xxxlight;
@LikeCounter-filledColor: @senary-color;

/* Private Mixin */

.LikeCounter-skinConstructor(@LikeCounterSkin) {
    color: @LikeCounterSkin;

    [class*="flicon-"] {
        fill: @LikeCounterSkin;

        polygon {
            fill: @LikeCounterSkin;
        }
    }
}

.LikeCounter {
    display: inline-block;
    text-decoration: none;
    color: @LikeCounter-defaultColor;
    .type(ui);

    [class*="flicon-"] {
        width: 14px;
        height: 14px;
        margin: 2px 5px 0;
        vertical-align: middle;
        fill: @LikeCounter-defaultColor;
        stroke: none;

        polygon {
            fill: @LikeCounter-defaultColor;
        }

        line {
            stroke: none;
        }
    }

    &:hover {
        text-decoration: none;
    }

    &.is-LikeCounter-filled {
        .LikeCounter-skinConstructor(@LikeCounter-filledColor);
    }

    &.is-loggedIn {
        &:hover {
            .LikeCounter-skinConstructor(@LikeCounter-filledColor);
            text-decoration: underline;
            cursor: pointer;
        }
    }
}
